import React, { useLayoutEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

export const Comments = () => {
	const [isOpen, setIsOpen] = useState(true);

	useLayoutEffect(() => {
		const comments = document?.getElementById("chapter-44")?.getElementsByClassName("comment");

		if (!comments) {
			return;
		}

		let index = 0;

		for (const comment of comments) {
			if (index % 2 === 0) {
				comment.classList.add('comment-gray-bg');
			}
			else {
				comment.classList.add('comment-no-bg');
			}
			index += 1;
		}
	}, []);

	return (
		<div className="chapter" id="chapter-44">
			<h2>What other people are saying</h2>
			<p>
				After posting my videos on youtube I received thousands of amazing comments. Some of them were pure gold to me as they condensed many years of experience just in a few sentences. You would not find that kind of advice easily on the internet. It should be adjusted to profession, city, age, mindset, so many thing. Making however sophisticated Google request still would not give anything close to the nuggets of wisdom that I got through those comments.
			</p>
			<p>
				Thousands real stories more or less confirming my conclusion!
			</p>
			<p className="mb-4">
				Here I’m attaching the comments that I consider the most insightful. I leave spelling and punctuation as it was originally posted.
			</p>
			{/* <Button
				onClick={() => setIsOpen(!isOpen)}
				aria-controls="example-collapse-text"
				aria-expanded={isOpen}
			>
				Open comments
			</Button> */}
			<Collapse in={isOpen}>
				<div id="example-collapse-text">
				<div className="comment mt-4">
					<p>
						Life quality is degrading everyday here in Canada. And housing is the core issue. Me and my GF are making $215K combined and still we aren’t considering buying a house. It is very expensive and not worth paying 1.2M for semi detached. Just not a good deal. Also weather is not in favour.
					</p>
					<p>
						Winters are depressing and summers are very short. I think moving to USA would be nice option. At least we would be able to find a house which is decent and doesn’t cost a fortune to buy. I think golden days are gone for Canada. Too much immigrants, dirty streets, no house, low wage jobs, government with no vision, and very bad work ethics from new immigrants.
					</p>
				</div>
				<div className="comment">
					I went back to Japan after living a decade in the Vancouver metropolitan area working as a software engineer. I'm very grateful to Canada for everything that the country provided, but unfortunately, I was working literally to survive and pay for the daycare, and expenses and save money for SOMEDAY (spoiler... that never came) to be able to buy a decent house not 100 km away from working place. Cheers from Osaka-JP. :)
				</div>
				<div className="comment">
					Canada was the place to be when I moved here at the age of 6...1973. It was the new frontier. So, I'm set up now and own a house just outside of Toronto, etc...but my 3 children, who are around your age, do not own homes. It breaks my heart to see them paying ransom-style rents every month. I am powerless in helping them, unless we all figure out some sort of a plan.
				</div>
				<div className="comment">
					<p>
						I am a born Canadian and lived here my entire life. Now in Toronto. It is absolutely terrible what is happening. Cost of living is increasing exponentially and our salaries are stagnant. Now, the BoC is raising rates to make it much worse for everyone. With inflation, we are what, paying maybe $200-400 most per family in total and now the rates increasing has raised my mortgage over $1000k and I'm being hit everywhere where I can barely make ends meet.
					</p>
					<p>
						It's unbelievable and I feel like the government is has lost its way. The future for our children is doomed at this rate. I would predict people leaving, suicides, etc. Thankfully, we are getting government subsidies for child care here in Ontario that are kicking in, because paying $2600/child while the wife makes barely more than that doesn't make any sense for anyone to have children or go to work.
					</p>
					<p>
						BUT, the government would rather immigrate people in Canada than having people have and raise children here. I feel helpless and completely upset that life has become like this. Basically if you're a shareholder, you push companies to reduce raises. Overwork their workers and increase profits....Sad, just sad…
					</p>
				</div>
				<div className="comment">
					Newcomers to Canada are brought here to just pay the rent and taxes. Same fate is in store for young Canadians, unless they are lucky to inherit a home from parents.  It does not help either that we have a incompetent prime minister who depends on advisers with an agenda.
				</div>
				<div className="comment">
					I'm a Canadian who has been working 10 years straight in Thailand. For 2 years before Thailand, I  was losing money every month as a "Regional Sales Manager" in Atlantic Canada... The money offered was an insult. Now 10 years in Thailand as  RSM working from home, I save 50-60% of my base pay live in 3 bedroom 3 bath home with a pool for 800 CAD a month.  My sister is a high level director in Fed Gov still in canada... She has been living pay cheque to pay cheque for 20 years now.
				</div>
				<div className="comment">
					<p>
						I arrived to Canada as a new born and had immigrant parents. I met a lot of kids growing up who recently came to Canada and their lives were immensely better than their home country. However now that im in the middle of my career earning 90 K all I see is new comers who complain about the state of the country, which is a far cry from what they were promised. Free healthcare, opportunity, excellent quality of life.
					</p>
					<p>
						Instead what we offer immigrants is artificially low salaries, obscene housing costs and some of the highest taxes outside of Europe. The Canadian government is scamming millions of immigrants and students using the former glory of what Canada use to be to trick them into giving up their lives back home only to be another cog in the machine to enrich Loblaws and RBC.
					</p>
				</div>
				<div className="comment">
					<p>
						I'm a junior developer in Toronto and affordability in this city, but also the rest of Southern Ontario, is horrendous. Despite having a wage which is higher than the junior developer wage-band in this video, I cannot afford to rent in Toronto, Waterloo, or really anywhere in Southern Ontario because if I did I'd have to throw away over 50% of my take home income into the rent pit alone. After normal living expenses, forget saving anything because you'll be left with a couple bucks if that. 
					</p><p>
						These arguments I hear from people like "oh just move somewhere cheaper" or "settle for less" are fucking stupid because moving somewhere cheaper usually means, like you said in your video, a big pay cut, which after taking into account housing prices still means you're either in the same boat as the more expensive city, or worse off. Young people in Canada just can't settle for less anymore because the "less" is still too damn expensive. 
					</p><p>
						I agree with your opinion on the Canadian immigration system, it is a big scam to get highly educated smart people from all over the world to work for shit wages. If anyone reading this comment is considering moving to Canada I'd suggest otherwise. I was born here and even I'm struggling, I can't imagine how bad it would be for a new resident who has no family or social support in the country already.
					</p><p>
						For fucks sake I went to school for 4 years, in a field that is in demand, I did everything right and our society still fucked me over.
					</p>
				</div>
				<div className="comment">
					<p>
						Originally I'm from Ethiopia and I came to Canada 🇨🇦 before covid 19 but nothing changed my life it only got worse Canada used to be the land of an opportunity but not anymore and it's very hard to get a job these days I'm in college and I tried to get an evening job but I wasn't able to find it even if you get a job you have to drive 1hr or more only someone who owns a car can get a job we struggle to pay for rent how can we own a car that's not easy or maybe impossible.
					</p>
					<p>
						I'm not saying Canada is a bad country or anything but it's not for me also I would like to go back home I have to look after my dad who still receiving some treatment also most of my relatives back home think we are having a good time in Canada and they expect us to support them financially but they don't know the real situation we are living in.
					</p>
					<p>
						I used to be a funny and happy guy but Canada changed my personality and it's been years since I felt real happiness in my mind it became my routine thinking and worrying about what will happen next ➡️ I think Canada is good for travelling and staying for weeks but not for a long time
					</p>
				</div>
				<div className="comment">
					I have been living in Canada for 30 years. Came here when i was 10 years old from Portugal. My parents had no choice as the labour market in Portugal back then was weak. I'm now married with a 3 year old child and i'm thinking of moving my family back to Portugal. I love it there. Sure, the wages in Portugal are no where near Canada's BUT the cost to live in Canada is much higher so it evens out. We have actually bought a condo in Portugal with the thought of maybe going there. We will see. Don't even let me start talking about the weather. lol.
				</div>
				<div className="comment">
					I grew up near Vancouver and never had a chance at owning anything. I should be a high paid professional, but that's not the case in Canada. Now I work for an American company remotely and live in South America. Finally, for once in my life I can get ahead financially.
				</div>
				<div className="comment">
					<p>
						I am a software engineer as well with a few years of experience. For a software engineer with an engineering degree why does life has to be so difficult that he/she needs to live month to month. Cant imagine how it is for people who do a less paying job. Just a small visit to grocery store 100 dollars goes away just like that. I feel like everything around me awesome but I am not part of it. Feel like lower class citizen.
					</p>
					<p>
						In silicon valley the rent is high, but there are also people who make a lot of money there. Here nobody makes that kind of money for the prevailing rent. Even the commercial properties are better when it comes to pricing their apartments. Private renters are notorious with their pricing. Pricing it as they wish. Basement apartments are almost 1300 CAD in Kitchener-Waterloo. I hate it when these long time settlers give advice like work hard and be patient.
					</p>
				</div>
				<div className="comment">
					I moved to Toronto in ‘89. It took me a month to figure out that Canada was not the US. Went back to my home country to regroup; and moved to New Jersey in ‘94. Best decision I’ve ever made. Certainly, things are rough globally…specially after the pandemic. But Canada takes the price.
				</div>
				<div className="comment">
					Canada nowadays is some variation of a pyramid scheme where newcomers contribute to the wealth of those who came earlier and managed to accumulate assets (like rental homes).
				</div>
				<div className="comment">
					<p>
						The nature in Canada is world class, it's an aesthetically beautiful country, but the opportunities are limited. My parents came to Canada in the late 80's and didn't speak English but could easily buy a house, back then everyone was approved. They paid off their home and raised a middle class family with only my father working.
					</p>
					<p>
						I'm 42 and still can't buy a house, I finished university and have a small business but my life is way harder than my parents. I rent houses and only dream of owning a home and gave up having kids because it's impossible to raise a family with 2 adults working full time, my husband and I.
					</p>
					<p>
						Life in Canada is extremely hard, extremely mentally straining. People are exhausted and hate their life. The Canadian dream is dead at the moment and we have our government to thank for the bad communist policies.
					</p>
				</div>
				<div className="comment">
					I’m born in Canada, also make good money and left Vancouver. It’s unfriendly, soulless, massively overpriced and full of braggers. The problem with Canada is the whole economy is based on resources, banking and real estate, and all of these are intertwined. I thought Canada was great until around 2015 then Trudeau was elected. It’s gotten more oppressive ever since.
				</div>
				<div className="comment">
					<p>
						I was born and raised in Canada but the situation is dire. I’m looking into emigrating to the US. I know a lot of the prospective immigrants to Canada just want to be anywhere but their home country but I hope they do their research and realize Canada is not “the one.”
					</p>
					<p>
						Canada changed drastically in the last 15 years. It used to be a great place, affordable, people were happy, jobs plentiful and housing was cheap. But since they went full in with real estate as the primary driver for the economy, the country tanked. Foreign investment, Rampant money laundering, govt and Bank capitulation have all contributed to it.. The culture and mindset of Canada has now. No more a country for the middle class...
					</p>
				</div>
				<div className="comment">
					Couldn't have said it better myself as a 2nd generation immigrant kid who is now a young citizen living in Metro Van for most of my life (19 years here). For as long as I've been alive housing prices have been insane even 10 to 20 years ago and the idea of starting a family and owning property here has been a pipe dream and fantasy that will never manifest short of divine intervention or miracle. Best to move to US or some wealthy country in northern and/or western Europe if you don't have a lot of ties keeping you here while you still can. Like you said the economy here is at least 50% real estate and a ponzi scheme market that only benefits the minority of property owners and politicians and asset rich multimillionaires whom we slave away for as indentured servitude.
				</div>
				<div className="comment">
					I am 68 and in good health. I left Canada because family doctors are extinct. Seniors are not faring any better and many will die from long waiting periods and a broken degraded system. I was not willing to wait for them to shove me into a home for the aged because of incompetence and non existant healthcare. Thank God I have a Euro passport, I now reside in Italy and the warm winter is a bonus!
				</div>
				<div className="comment">
					This is insane; one of my friends bought a home in a county with no hospital or large grocery store, three hours drive from Calgary, for $400K, while my sister bought a house in the city of Dallas, Texas for CAD equivalent 250K.
				</div>
				<div className="comment">
					When I migrated to Canada I was in a top earning bracket but it was a struggle to keep up with the payments. It gets worse once you have a family and buy a house, you literally can't afford much after that. Lifestyle is great though, beautiful sites and scenery, lovely people and communities. What I think Canada needs is to create more opportunities in other sectors, encourage entrepreneurs and people with ideas, create enabling environment for other sectors to flourish especially in those areas that are mono sector based and they shouldn't view new entrants or business ideas as a threat but opportunities for growth and diversification of the economy
				</div>
				<div className="comment">
					Canada was the place to be when I moved here at the age of 6...1973. It was the new frontier. So, I'm set up now and own a house just outside of Toronto, etc...but my 3 children do not own homes. It breaks my heart to see them paying ransom-style rents every month. I am powerless in helping them, unless we all figure out some sort of a plan.
				</div>
				<div className="comment">
					I'm not new to the country - I'm Canadian, and all these issues make it impossible to get ahead. Housing in particular is a challenge - we have the second-largest country in the world, but buying a house is an impossibility for entire generations of hardworking families, even in a small town nowhere near as expensive as Vancouver. It's insane.
				</div>
				<div className="comment">
					Exactly my experience in Canada! I had an accident, not life threatening, but I did need medical assistance, it took 18 hours with no pain killer in the waiting room to access a doctor. I would have been better to just fly to Mexico, have it fixed and return! And people here do not even want to make more money, as the more you make, the more taxes you pay! Even on low paying jobs, no one wants to work extra time, for if you work extra time, that extra money will have higher taxes, so you would end up earning no extra income!. And yes, owning a home here is my shattered dream… impossible
				</div>
				<div className="comment">
					i just want to have the citizenship and move to a different country because the cost of life here is not sustainable!
				</div>
				<div className="comment">
					I came to Australia with hope and energy. Paid full price for university. But soon after I graduated with 5000 dollars in my bank account, I realized that I might be doomed for the rest of my life. No matter how hard I work, I can’t compete with those rich immigrants and older generations. I don’t know what I can do to have a life that they have. Basically working is only paying the bills, with no extra money to explore my hobbies or anything travel. Worked all year around, apart from my age is growing. I don’t feel I have a life here. I only survive here. Some people saying being poor is a disease. I don’t know what is the cure. Life will slip away quickly. I don’t want spend the rest of my life surviving. When your home country is worse and where you live is mess. I really starting to lose hope. Not exactly feeling the hope and energy when I first landed in Australia. I guess this is nobody’s fault, except for me be poor,I’m sorry.
				</div>
				<div className="comment">
					I came to canada 6+ years ago with a lot of dreams. After initial job search of few monhs got a job in Cybersec and was too happy. At start salary was low then became better but I liked the work culture and politeness of people the most so still was happy but then pandemic strikes, lost the job and couldn't get it back again as companies became selfish , froze jobs, they started looking for very specific applicants who probably hv less/no experience so that they can pay them less for 2-3 years n then throw them, the JDs hv become too complex and HR/recruiters hv no idea what they're looking for and what's the meaning of experience as everyone hv their own criteria in mind.Currently, they only inivite applicants whom they want to pay less. Now leaving Canada but happily a Citizen now as thats the only option left after consuming all the finances. Moreover, after introduction of work-from-home culture during pandemic , i don't think that people working in technology related fields will find much value immigrating to Canada. US market is way bigger as compared to Canadian market. All companies here want to pay less and have become very very selective (10 times more selective) as compared to pre-pandemic selection criteria. Government is not doing much and corporate world is getting even worse. My time on job was really good in Canada but post pandemic situation is really bad here specially with lost job and no chance of getting a new one despite having over 3 years "canadian experience". Add one more problem which has started specially in Toronto that people already working here have become insecure from incoming immigrants and now there is an (internal) war like situation in medium to small organizations where everyone is trying to secure their own job and trying to kick out others who can become possible threat for their future job security . Good luck to everyone.
				</div>
				<div className="comment">
					I am born and raised in Canada and it's hopeless… I'm stuck with a minimum wage job and only hope to find something better but there is so much competition for the same job… The system is set up for the rich.
				</div>
				<div className="comment">
					Vancouver house prices were going out of control....it is mainly the insanely low interest rates from the 2000's to early 2022....this resulted in the rich who have access to capital to buy up everyting and inflate all assets which makes them even richer...
				</div>
				<div className="comment">
					Canada is an imaginary country. Its international image is deliberately fabricated and very misleading. In fact, it is a trap. Even rooted Canadians dream leaving Canada for USA, Europe, and they leave with first opportunity. Immigrants from developing countries leave for other countries or get back to homeland as soon as they get their Canadian passports. Canada is an illusion. Beware!
				</div>
				<div className="comment">
					Before moving to Toronto in 2018, I had the dream of living the "Canadian Dream". I have seen so many documentaries about Canada and the beautiful landscapes, the great quality of life and the kindness of the people. I chose Toronto, ON because again, based on my searches, they were saying that it was easy to find a job, cheaper than Vancouver and had more activities to do as a young man in his early 20s. I left the country a year later... The only positive thing I retain was that I have met great people and have made friends with people from all around the world.
				</div>
				<div className="comment">
					As a 26 year old Canadian you hit the nail on the head. Unless you were born into money, owning a home is unaffordable in the big cities. And you have to live in the big cities because that’s where the jobs are. Furthermore, Covid exposed how broken the healthcare system is here. I love Canada but in the US you get paid more in a stronger currency, taxed less, and the cost of living is lower.
				</div>
				<div className="comment">
					I was born in Canada. I make the same wage as u as a logger. And my family are barely making it. My kids will never afford a house. I feel your pain
				</div>
				<div className="comment">
					Everything you described is exactly what is happening in Australia also. Coincidentally it's even worse in Ireland (my home country). I wouldn't hold your breath on Conservative politicians fixing it either, its a bipartisan Ponzi scheme. Personally me and the wife just plan on saving and investing, then later in life buying a place in a lower cost of living country to see out the days.
				</div>
				<div className="comment">
					I live in brampton, and I've talked to a lot of immigrants, especially from India. They all say the same thing. They regret coming here because it's too financially difficult here. The cost of living here is higher, property costs are insane, there are longer working hours than most countries, and transportation is a must. As a result, they come here and have a lower quality of life and often times left family.
				</div>
				<div className="comment">
					I’m born and raised in Canada. Everything you said is true. I’m lucky enough to own a home and I have a decent job so life is not so bad for me. But I have many younger family members and friends that are not do lucky. They live at home in their parents basement in their 30’s. Even smaller towns became very expensive. You’d have to go to a very remote area to find cheap property. High taxes , high cost of housing, bills, heating, cold weather. On the bright side Canada is a nice clean safe country with first world infrastructure
				</div>
				<div className="comment">
					I'm 28 and stuck living in my parents basement! I work in tech and make a good salary, plus I'm single, but I still can't justify leaving my parent's house even though I'm not happy living at home. The whole country is simply too expensive, you haemorrhage half of your earnings in high taxes and absurdly high housing costs.
				</div>
				<div className="comment">
					I am an immigrant from Belgium. Health care is really bad . No family doctors . I am going back to Belgium for surgery because the wait time to get surgery is it’s like forever and I’m living in pain . It’s hard to progress in Canada. All you do is work, pay bills and repeat. You are lucky if you can afford a house . Not very much fun stuff going on here either . Canada is a great country if you are ok living paycheque go paycheque .
				</div>
				<div className="comment">
					I had my immigrant dream in Australia for almost 10 years. Beautiful country, but unlike Canada, with an excellent climate all year round particularly in cities like Sydney, Brisbane, Melbourne, but I did find it so hard to own a place because of the super high housing prices. Renting business is huge in Australia too, so renting is also very expensive and add to the equation the super high cost of living I found myself earning enough just to pay my rent and get by. Now, healthcare was good down there on Medicare. I did have my visits to physicians, even had a procedure at a hospital and didn't have to worry about medical attention. But half my wage went straight to the taxation office. So, long story short I couldn't afford my own house or place which is pretty frustrating, and the most affordable places were far out from workplaces or places I wanted to live in or nearby. So I decided to kiss my australian dream good bye and get back home where now I own my place and even though salaries are low, costs of living are low too. By the way, I mentioned New Zealand because I know things are worse there. In fact a lot of New Zealanders migrate to Oz for work, live and work there for a long while and retire back in beautiful NZ.
				</div>
				<div className="comment">
					I was born and raised in BC but have lived overseas for the past 20 years. Over the years, I used to think that I would move back there some day with my wife and kids, but in recent years I have come to terms with the fact that this probably wont happen. I have been watching my beautiful homeland deteriorate from a far and it is very saddening.
				</div>
				<div className="comment">
					I’m Ukrainian Canadian who lived in Toronto for 30 years before leaving Canada in 2019 because of those issues you’re talking about. I do return once a year to see my family but even that is hard to do today because my very basic (2-3 week) yearly visits back home costing me 4-6k $CAD., Where I live now in South East Asia same money goes a long way.
				</div>
				<div className="comment">
					Canada is set up for native born boomers. Immigrants are seen by the government as a new taxation target to keep social programs for the me generation going, they even admit it.
				</div>
				<div className="comment">
					I'm Canadian, and all these issues make it impossible to get ahead. Housing in particular is a challenge - we have the second-largest country in the world, but buying a house is an impossibility for entire generations of hardworking families, even in a small town nowhere near as expensive as Vancouver. It's insane, and you have described the causes of the crisis very accurately.
				</div>
				<div className="comment">
					Hey, as a first gen Canadian, I pretty much lost hope in buying a house in Canada. Gas and food prices are making me depressed I finish college next December so I would like to get a job in the US or at least get a decent paying job (im in the tech industry also) after I graduate since my program comes with work terms so im hoping that will help.
				</div>
				<div className="comment">
					Ex-Torontontonian and Ex-PR of Canada here. I left Canada in 2019 for familial reasons, but considering coming back this year. Even got two rounds of job interviews to work in my field. Definitely had good chances to get a job, but then I checked renting prices. Back my job search, I was living in Zurich, Switzerland which by no way is a cheap city to live. Yet, I had cute 1 bedroom apartment for less than 1/3 of my net income. Then I realized my 2,000 budget will give me studio only. Once I realized it I withdrawn my application. Instead, I accepted a job offer in Liechtenstein and live in cute Swiss town. Here, 1/3 of my salary gave me gorgeous 2 bedroom apartment. I am missing Canada terribly. I am missing feeling welcome, meeting pepole from around a word, socializing opportunities. I have none of this here. But I have friends thanks to the Internet. Feeling welcome and opportunities will not pay my rent.
				</div>
				<div className="comment">
					I fully agree with you. Canada does not have enough good paying professional jobs. Even if you get a job, salary is so low that you would not have a descent life. I was in Canada for four years and even achieving a Masters degree from the famous University of Waterloo, I was offered only $45,000 annual salary. What a joke!!!
				</div>
				<div className="comment">
					All accurate. I’m born in Canada, also make good money and left Vancouver. It’s unfriendly, soulless, massively overpriced and full of braggers. The problem with Canada is the whole economy is based on resources, banking and real estate, and all of these are intertwined. I thought Canada was great until around 2015 then Trudeau was elected. It’s gotten more oppressive ever since.
				</div>
				<div className="comment">
					<p>
						They lure educated young people in my countries with the promises of opportunities, that they need people with knowledge & experience. When we came in, we played by their rules, from studying in their education institutions, to paying taxes & re-building our credit in here. Paid 3-times as much as what Canadians paid for tuition fees, then we're on our own to survive.
					</p>
					<p>
						Furthermore, we're abandoned from every aspects. When we graduated, we were mistreated by the things called "CANADIAN EXPERIENCE". They brought us here and hope that we may change our career from business, IT, analyst... to construction workers,wielders to fill the gap... ???? Why didn't they, initially, advertise that they need construction workers, general labourers so save out times
					</p>
					<p>
						IT MADE NO SENSE AT ALL. I left Canada 10 days ago, never felt more alive. I just hope whoever feel stuck, they can find a way to figure out. To me, it is a dead land masquerading as a first-world country, with the illusion that they're more superior. Like please, In America they have Silicon Valley, in Vietnam they have Quang Trung Software Park. In Canada, I have never heard about an IT hub in Canada. If you need people to pay for the tax because of the aged population, just admit it first, then build the programs to facilitate new immigrants. Don't just bring them here for money then treat them like cattle with disdain.
					</p>
				</div>
				<div className="comment">
					Fifty, sixty years ago Vancouver was a lovely city providing a great life style for working people. How can it be that a resource and land rich country like Canada can have the serious problem of unaffordable housing? Something is seriously wrong when the wealthy get wealthier and the working people get poorer.
				</div>
				<div className="comment">
					Yes! I was born and raised here but the situation is dire. I’m looking into emigrating to the US. I know a lot of the prospective immigrants to Canada just want to be anywhere but their home country but I hope they do their research and realize Canada is not “the one.”
				</div>
				<div className="comment">
					<p>
						I worked 3 jobs in Canada in 16 years. First was plumbers helper (Masters degree in Mechanical engineering). 16$ hour, found via social connections. Second was junior shipboard engineer. 28-35$ hour depending on the ship plus OT. Social connections. Third job after I got my full fledged license 65$ hour, government mechanical engineering inspector. Very advanced social connections, I used to work with the guy who then joined government and recommended me.
					</p>
					<p>
						Took me 11 years to get license. In 5 years after that I left Canada because the country went into tyranny mode. All my work to get licensed went to shit thanks to Turdo and obedient Canadian sheeps. Without social connections forget about having a decent job. You need someone who knows someone who knows who's hiring. It's harsh over there, new immigrants.
					</p>
				</div>
				<div className="comment">
					My mom came to Canada in the 90s and was a top rated doctor from Syria. She ended up spending her time in Canada doing every odd job from cleaning and painting, to car sales and real estate. She was never able to practice medicine, even after passing the exams in Canada, and it’s a shame because she had SO much to offer the healthcare system.
				</div>
				<div className="comment">
					I immigrated to Canada in the mid 2000s and worked and lived in Mississauga, ON and Montréal, QC. I left Canada in 2012 when I landed a job in the Middle East. But I hear the costs of living skyrocketing in Canada from familles and friends, and it keeps me from ever returning. And the winter weather in Canada is awfully unbearable. Living through more than seven severely freezing winters is enough for me.
				</div>
				<div className="comment">
					A   s a child of immigrant parents I really enjoyed growing up in Vancouver in 80-90s but after getting my Bachelors & Masters I worked in US & Asia. Salaries, career opportunities are better & overall living is better. Canadians right now have it tough, political elites have created a mess in housing as majority of MP’s are multiple property owners. Healthcare is falling apart as I visit my ageing parents regularly.
				</div>
				<div className="comment">
					I’m a SWE who moved to Vancouver from India through my company. On paper the package looked good, but it’s incredibly expensive. I made north of 85K last year, and yet feel poor. I’m told 80K is pretty good for Canada, but I’ve yet to meet someone making ends meet and being able to save enough to start a family here. Most immigrants who have houses rarely have jobs that would allow them to afford said property. It’s a monopoly here friend. The video hits home. PS I work one block from you. I was surprised I’m not the only one feeling this way. Most of my colleagues were born here and will inherit a house or sometime four. Thus, they can’t understand the struggle
				</div>
				<div className="comment">
					As a Vancouver native who went to school there but left to NYC for SWE job. I would strongly agree especially for Vancouver, it’s very true that it’s for if you are already rich, or else housing affordability and ridiculous high taxes and low salary will not be setting you up for success as a SWE.
				</div>
				<div className="comment">
					<p>
						I agree with your thesis, my family came to Canada in mid-70s. They bought their first house a year later for $35K, 10 years later they moved to another house $70K . 10 years after that they moved to a $250k 3000 sq ft home, 2.5 time gross salary. My parents were not professional, just blue collar. We had a good life, better than we would have in south western Europe at that time.
					</p>
					<p>
						My parents paid for the kids to go to university. Non-professional Immigrants  back in the 70s to 90s could attain a comfortable middle class life. Something changed in the 2000s. I think if my parents were making the same decision in the early 2000s they would have stayed in Europe. My south american friends that came to Canada in the early 2000s got their Cdn citizenship have all left and gone somewhere else (mostly USA, some to EU)
					</p>
				</div>
				<div className="comment">
					This video hits home, big time. I'm a US citizen who moved to Canada almost 5 years ago. Fortunately, I live in a border city, and I've only worked for US based companies and have made USD. However, looking at essentially the same exact job title over here in Canada, you're making about 20-30% less, as well as higher COL in many areas. Taxes are a bit higher here, and sales tax in my province is 13%, compared to the 6% in the US state I'm originally from. I feel for Canadians over here that almost always have to have dual income households, both people both having to hold Full-time jobs.
				</div>
				<div className="comment">
					The problem is things have gotten too complicated and employers want way too much experience for even a low salary junior position. I graduated in 1992 from, not university, but a 5 month college course, tuition and living expenses paid by the government. In under 2 years I was at IBM. I have been an MS-Access developer for 30 years. Nope no Java, no C, no .NET, just Access/VBA. I bought a house in Toronto and raised a family of 3 , on a singe income. The wild wild west days of programming where we were treated like gods is gone, but I am glad I lived through it. Hey, the party for my generation is not over yet, I have worked from home for a bank the last 8 years, average 2 hours a day for a 6 figure income.
				</div>
				<div className="comment">
					Great video man! Keep your head up while you wait for the citizenship. I’m also a software engineer and became Canadian citizen in Feb 2020. I moved to the Bay Area in June 2020 with my wife and never regretted leaving Canada. 2x salary, and an overall lower cost of living in the USA makes it worthwhile. Let me know if you ever have questions I’m happy to help to the extend I can. Cheers.
				</div>
				<div className="comment">
					<p>
						Finally someone that tells the truth and doesn't sell yet another success story (although that can be the case occasionally)
					</p>
					<p>
						Real estate is a monster that eats your salary, that's especially true on more fortunate nations that had a real good living 10-20 years ago, now real estate is yet an extra tax unless you have a house already.
					</p>
				</div>
				<div className="comment">
					Born here, went to school here.  First day of college, the instructor said "if you want to get rich, you're in the wrong class."  He was right, and that was decades ago.  If you want to chase money in tech, you head south.  It's a thing, something like 300,000 Canadians working in the US, if you can believe the stats.  Mostly tech, though lots of nursing too.  But, there's more than chasing money.  I've stuck it out here, had a decent life.  You make your choices, you live with them.
				</div>
				<div className="comment">
					Another option is to move to where living expenses are less.  More of an option for those in resource sector jobs, but tech is in more than just the big cities.  If you can handle the weather, check out the job boards for government and education... put an application in even if you don't meet the posted qualifications.  You might be surprised.
				</div>
				<div className="comment">
					Things are changing right now.  Boomer are retiring and there are a lot of unfilled positions in tech. They say wages are going up fast, but I've not seen it yet.  Again, not into chasing it.  The big immigration push from the government is because of this.  They are looking ahead... and it ain't pretty, at least from an employer prospective.
				</div>
				<div className="comment">
					As a software dev with 20+ years experience, 4 year computer science degree and home grown from Canada in the tech sector here - the fulltime software development roles here pay garbage compared to other tech companies in the US like Google/Apple/Facebook etc.  A highly senior dev role you're lucky to get 120K (That's Canadian dollars)
				</div>
				<div className="comment">
					Many people tolerate Canada for 4 years. Get passport and get TN visa and move the US for good.
				</div>
				<div className="comment">
					I'm a native Canadian, born and raised. As you noted, this country is for the immigrants who are already rich. But it's also for the Canadians who are already rich. My entire friend group and I are completely hopeless about our future prospects in this country. I'm not even talking about home ownership--we kissed that dream goodbye years ago.
				</div>
				<div className="comment">
					Most big cities in Canada are just unaffordable to live in for the vast majority of ppl without spending a ton on living expenses, without anything left to save or invest.
				</div>
				<div className="comment">
					The COL to salary ratio are messed up in Canada. I moved to Canada in 2013. The first house we rented was 1400$ and it was a 4 bed 3 bath house in downtown Mississauga. Now we live in a 3 bed 4 bath in the suburbs and pay 3200 $ a month for a house almost twice as small. Thats why Im getting my CS degree and moving to Texas or some other low income tax state for the higher pay and lower COL.
				</div>
				<div className="comment">
					Every new immigrant or would-be immigrant to Canada needs to watch this video first!<br/>
					It's an eye opener!<br/>
					Immigration lawyers and consultants don't want their customers to know this info as they may get cold feet.<br/>
					Or choose another country to settle down (especially those that are in high demand such as doctors and software engineers)<br/>
					I believe that I'm going to be renting till I retire, there is absolutely no hope in Vancouver or metro Vancouver to own a 2 bedroom.<br/>
					Unless you make a combined family income of  more than 250K a year!<br/>
					It is really ridiculous.
				</div>
				<div className="comment">
					The provincial government is not doing much and  the fun part is they blame the Federal government.<br/>
					Yet we'll keep on bringing more immigrants without having a place for them to live<br/>
					We need the people but we bring them to suffer!<br/>
					Make no sense why can't we link the immigration system with housing and jobs? Everything is computerised these days, how hard would that be?
				</div>
				<div className="comment">
					CANADA SUCKS for SWE and everybody knows it. The ten SWE that I personally know from UWaterloo, only one has stayed in Canada, the rest have all gone to the US, the difference is night and day. Canada is just a lazy, uncompetitive backwater where people make lower salaries, get taxed to death (but muh free healthcare, meanwhile most US white collar employers cover most health expenses anyways) and suffer housing costs borne from foreign real estate speculation and foreign money laundering. God knows if the 2 year moratorium for foreigners buying real estate will actually work.
				</div>
				<div className="comment">
					<p>
						I lived in Canada for over 14 years, I was able to get the Canadian dream, I bought a house and 2 cars, I was able to had some investments and got the citizenship, but after 10 years of buying my house I experienced what you have described, I now realized that I was lucky to get the economic wave on the right moment, at least I was able to buy a house, because my salary as Software Developer in Toronto didn't cover up all my expenses, every month I was sinking in more debt because the cost of living always has been high nowadays is simply impossible.
					</p>
					<p>
						Now I realize I was visionary of todays reality 3 years ago I felt that Canada was going into the current direction, so I sold my house and payed all my debts before the bank suck all my networth and returned to my country origin and was able to avoid living in current nightmare... now I have a pretty good job bought a house and live with my people, avoiding freezing weather, cold distant people, being an economic slave of current economic system... I hope Canada may become a better place to live in the future…
					</p>
				</div>
				<div className="comment">
					Canadian citizen here. I live in Halifax. Just watched rent double for a 1 bedroom in 2.5 years. What did salaries do? Went up 5%. Cost of living, taxes, and poor salaries, means even in a "high paying" career like software development you will live paycheck to paycheck. I'm looking to leave ASAP.
				</div>
				<div className="comment">
					I can say you nailed it mate. I live in Vancouver, we're a family of 4. We've been here for a year and my wife is having trouble finding a job so we're living only with my salary. 120k/yr, after taxes I get 7k/month. 3k rent, no car but that expense gets replaced by the 2 kids school and activities expenses so we are barely making ends meet, sometimes chipping away 200-300CAD from savings which are running low (below 4k right now). My salary increase was 3% (I used to make 114, now 118~). How much increase was that on my monthly payment?, 60CAD... If my wife doesn't get a job and prices continue to go up we'll be kicked out of Canada(Vancouver) because we can't afford it. Shit is not looking good at all. This is nothing like the Canada I knew when I visited in 2015 (the one I wanted to move to).
				</div>
				<div className="comment">
					<p>
						That country never needs people with knowledge/experience, scholars or talents. They need cheap labor, so their tactics is abusing international students as cows to milk their budget, then after spending a huge amount of money to boost their economy & tax, you work as general labourers to survive. 
					</p><p>
						They make themselves look like a first-world country, whereas product availability is scarce, the available land for housing is largely occupied by speculators who want to keep the price rising. They don't have jobs, so they masquerade their job market with the "CANADIAN EXPERIENCE"", the sectors that provides most of the jobs are construction, drivers, factory machine operator, salespeople. Why, because the digital transformation is worse than South East Asia. Digital Marketing is not even a thing in Canada, whereas in Singapore, Malaysia, Thailand, Vietnam, Laos, they have: Tiki, Shoppee, Lazada...
					</p><p>
						If you love the cold, gloomy weather expanding for 6 months, or coming from a country that is in chaos / war, Canada might be good enough for you.
					</p><p>
						For those who are well-educated, have experience globally, Canada is the grave for your career
					</p><p>
						What a weird country. Inviting scholars/professionals into their land, then treat them with disdain, while still complaining about development and economy
					</p>
				</div>
			</div>
			</Collapse>
			</div>
	);
};
