import React from 'react';
import { TableOfContentsList } from './TableOfContentsList';

export const TableOfContentsHard = ({ isAuthenticated }) => {
    return (
        <div id="table-of-contents" className="mb-4">
            <h1 style={{ marginBottom: '1rem' }}>Table of Contents</h1>
            <TableOfContentsList isAuthenticated={isAuthenticated} />
        </div>
    );
};